import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../layouts/history-timeline'

export default (props) => <Layout {...props} />

export const query = graphql`
{
  allHistoryTimelineYaml {
    edges {
      node {
        id
        period
        content {
          image
          text
          title
          position
        }
      }
    }
  }
}
`
