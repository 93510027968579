import React, { Fragment } from 'react'
import ScrollMagic from 'scrollmagic'
import { TweenMax, TimelineMax } from 'gsap'
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap'

import Layout from '../components/layout'
import SEO from '../components/seo'

import '../styles/history-timeline.css'
import helper from '../utils/history-timeline'
import { parseEdges } from '../utils/index'
import { pathOr } from 'ramda';

const windowExists = () => typeof ScrollMagicPluginGsap === 'function'

if (windowExists()) {
  ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)
}

export const sceneMaker = function (itemId) {
  if (windowExists()) {
    const controller = new ScrollMagic.Controller()
    const triggerElement = `#item${itemId}`
      
    const tween = TweenMax.to(triggerElement, 1, { className: `+=active` })
    const scene = new ScrollMagic
      .Scene({ triggerElement, duration: 2300, offset: -200 })
      .setTween(tween)
      .addTo(controller)

    return scene 
  }
}

const parseNode = ({ content }) => {
  content.forEach(item => {
    sceneMaker(item.id)
  })
}

const isFirst = (idx, i) => idx === 0 && i === 0 ? 'active' : ''

const setPosition = (position) => position === 'right' ? 'timeline-item-even' : 'timeline-item'

const TimelineItem = ({ data, idx }) => (
  <Fragment>
    <div className="timeline-period">
      <div className="timeline-period__title">
        {data.period}
      </div>
    </div>
    {data.content.map(({ title, text, image, position, id }, i) => (
    <div className={`${setPosition(position)} ${isFirst(idx, i)}`} dataText={title} id={`item${id}`} key={i}>
        <div className="timeline__content">
          <img className="timeline__img" src={image} alt={text} />
          <p className="timeline__content-desc">{text}</p>
        </div>
      </div>
    ))}
  </Fragment>
)

const TimelineItems = ({ dataList }) => (
  dataList.map((data, idx) => <TimelineItem data={data} idx={idx} key={idx} />)
)

const HistoryContent = ({ dataList }) => (
  <div className="timeline-container" id="history-timeline">
    <div className="text">
      <div className="row">
        <div className="col-xs-10 col-sm-10 col-md-8 col-lg-7 mx-auto text-center">
          <h1>History of the PGA</h1>
          <div className="header-nav">
            <span className="active">PGA HISTORY</span>
            <a href="/history/hall-of-fame/">HALL OF FAME</a>
            <a href="/history/past-presidents/">PAST PRESIDENTS</a>
            <a href="/history/pga-annual-awards/">PGA ANNUAL AWARDS</a>
          </div>
        </div>
      </div>
    </div>

    <div className="timeline-main-container">
      <TimelineItems {...{ dataList }} />
    </div>
  </div>
)


export default (props) => {
  const { pageContext, location, data } = props
  const { frontmatter } = pageContext
  
  const edges = pathOr([], ['allHistoryTimelineYaml', 'edges'], data)
  const parsedData = parseEdges(edges)
  const dataList = parsedData.map(o => ({
    ...o,
    content: o.content.map((c, id) => ({
      ...c,
      id
    }))
  }))

  setTimeout(() => {
    dataList.forEach(node => parseNode(node))
    helper()
  }, 1200)

  return (
    <Layout {...{ location, frontmatter }}>
      <SEO title={'history'} />

      <div className='layout-default'>
        <div className='col-lg-12 p-0'>
          <HistoryContent {...{ dataList }} />
        </div>
      </div>
    </Layout>
  )
}
